.header {
  background-color: $colour-primary;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;

  // Slowly transition between background colours
  @include transition(background-color 3s);

  .logo {
    border-radius: 100%;
  }

  .logo,
  .gravatar {
    margin: 10px 10px 10px 0;
  }
}
