// Restrict textarea resizing.
textarea {
  max-height: 264px; /* This is to prevent the autosize library getting too crazy */
  min-height: 60px;
  resize: none;
  word-wrap: break-word;
	
  &.short {
    min-height: 37px;
  }
}

textarea#content {
  margin-left: 0 !important; /* This rule is to override the styling applied as a result of a WCAG AA compliant id being applied */
}

label {
  p {
    display: inline-block;
    margin: 0;
  }
}

.autocomplete-control {
  overflow: visible !important;
}

.attachment-control {
  position: relative;
	
  .control-label label { display: inline; }

  .remove-button {
		
  }
}

.remove-button {
  @include position(relative, 3px 0 0 0);
  display: inline-block;
	
  a {
    @include size(16);
    @include sprite-position(7, 0);
    background-image: url($sprite-main);
    display: block;
  }
}

.control-heading {
  margin-bottom: 16px;
	
  h2 { line-height: 1.4em; }
}

.inset {
  border: 1px solid #ddd;
  margin-bottom: 15px;
  padding-top: 15px;
}

.form-group {
  label {
    display: inline-block;
    vertical-align: middle;
  }

  [disabled] {
    background-color: $colour-disabled-input !important;
    opacity: 1;
  }
}

.upload-label {
  h3 {
    display: inline-block;
    vertical-align: middle;
  }

  .af-icons {
    top: 9px;
  }
}

// Help text that appears under a field.
.help-text {
  color: #999;
  display: inline-block;
  font-size: 0.923em;
  margin-top: 5px;
}

// Hint text that appears under a field.
.hint-text {
  p {
    margin: 0 0 1em 0;
  }
}

form {
  .header {
    margin-bottom: 0;
  }
}

/* Actions styles */
.form-actions {
  margin-top: 50px;
	
  &.centered { text-align: center; }

  .control-field { width: 100%; }
	
  button, input, a {
    margin-bottom: 10px;
    margin-right: 5px;
  }
}

.form-actions-sm {
  margin-top: 30px;
}

.form-actions-xs {
  margin-top: 10px;
}

/* Change the default focus colours */
input:focus,
textarea:focus,
select:focus {
  border-color: $colour-input-border-focus;
  outline: none;
}

input.price-field { width: 70px; }

input.two-digit { width: 50px; }

.form-inline {
  .form-control {
    &.date,
    &.datetime,
    &.timezones { width: 49% }

    &.api-token {

    }
  }
}

.cc-expiry {
  .column-quarter:first-child { width: 50px; }
}

// Specific to the roles checkbox list.
ul.roles li {
  margin-bottom: 15px;
  margin-right: 15px;
}

// This is used on the judging groups page.
ul.groups li {
  + li { margin-top: 5px; }

  input { margin-right: 6px; }
}

// For client-side validation errors.
div.error,
span.error {
  color: $colour-error;

  input,
  textarea,
  select {
    border-color: $colour-input-border-error;

    &:focus {
      border-color: $colour-input-border-error-focus;
    }
  }
}

// Required fields
span.required { color: $colour-error; }

div.disabled,
label.disabled {
  background-color: transparent !important;
}

// For any element that is disabled.
[disabled],
[disabled=disabled],
.disabled,
.disabled-form input,
.disabled-form select,
.disabled-form textarea {
  background-color: $colour-disabled !important;
  @include opacity(0.5);

  &:hover { cursor: not-allowed !important; }
}

// Text that is disabled, doesn't look so nice with the background colour.
.disabled-text {
  @include opacity(0.5);

  &:hover { cursor: not-allowed; }
}

// File uploads field
.file-upload-container {
  overflow: hidden;
  position: relative;

  .file-upload-visual {
    @include position(absolute, 2px 0 0 0px);
    @include size(100% 34);
    z-index: 1;

    // Hide this for IE.
    @include ie {
      display: none;
    }

    .visual-field {
      @include position(relative, -3px 0 0 0);
      background: white;
      border-radius: $default-radius;
      border: 1px solid #d5d5d5;
      color: #aaa;
      display: inline-block;
      margin-right: 10px;
      padding: 8px;
      width: 65%;
    }

    .button {
      padding-top: 7px;
    }

    @include lt-ie9 {
      .visual-field {
        top: -2px;
        padding: 6px 8px;
      }
    }
  }

  .upload-field {
    @include opacity(0);
    @include position(absolute, 0px 0px 0 0);
    @include transform(translate(225px, -25px) scale(6));
    height: 36px;
    position: relative;
    z-index: 3;

    @include ie {
      @include opacity(1);
      @include transform(translate(0, 0) scale(1));
      position: static;
      width: 300px;
    }
  }

  .upload-details-container {
    overflow: hidden;
    //margin: 5px 0;
  }

  .upload-details {
    float: right;

    .sort-order {
      vertical-align: top;
    }

    .move {
      font-size: 95%;
      vertical-align: middle;
    }
  }

  .upload-abort-button {
    float: left;
  }
}

.upload-progress-container {
  @include size(100% 10);

  .upload-progress {
    background: $colour-secondary;
    height: 10px;
  }
}

.document-container,
.upload-complete {
  margin-top: 5px;

  .filename,
  .filesize,
  .file-edit {
    margin-right: 20px;
  }

  .filename {
    display: inline-block;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

#ie-uploader {
  @include size(100% 350);
  margin-bottom: 15px;
}

// Input field placeholders
$colour-placeholder: #999;

::-webkit-input-placeholder { color: $colour-placeholder; }

:-moz-placeholder { color: $colour-placeholder; }

::-moz-placeholder { color: $colour-placeholder; }

:-ms-input-placeholder { color: $colour-placeholder; }

.ie-placeholder-container {
  position: relative;

  .ie-placeholder {
    @include position(absolute , 10px 0 0 10px);
    color: $colour-placeholder;
  }
}

.form-display {
  .form-label {
    font-weight: bold;
  }

  .form-value {

  }
}

.word-count, .character-count {
  float: right;
}

.radio .radio-description {
  margin-top: 7px;
  margin-bottom: 25px;
}

// WCAG AA Compliance: https://trello.com/c/74e7D0Ef/178-wcag-aa-compliance
input[type=checkbox]:focus,
input[type=radio]:focus {
  outline: dotted 1px #000;
}

// Checkbox inside <td> element
td > .checkbox {
  text-align: center;
  margin-top: 0;

  input[type=checkbox] {
    position: relative;
    margin: 0;
  }
}

.indent-option {
  margin-left: 25px;
}
