@font-face {
  font-family: 'af-icons-lg';
  src: url('../fonts/af-icons-lg.eot');
  src: url('../fonts/af-icons-lg.eot?#iefix') format('eot'),
    url('../fonts/af-icons-lg.woff') format('woff'),
    url('../fonts/af-icons-lg.ttf') format('truetype'),
    url('../fonts/af-icons-lg.svg#af-icons-lg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@mixin af-icons-lg() {
  display: inline-block;
  font-smooth: never;
  font-family: 'af-icons-lg';
  font-variant: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.af-icons-lg {
  @include af-icons-lg();
}

.af-icons-lg-animate-rotate {
  font-size: 16px;
  width: 16px;
  height: 16px;
  display: inline-block;
  animation-name: rotateAFIcon;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotateAFIcon {
  from { transform: scale( 1 ) rotate( 0deg );   }
  to   { transform: scale( 1 ) rotate( 360deg ); }
}




.af-icons-lg-settings:before {
  content: "\E090"
}


.af-icons-lg-criteria:before {
  content: "\E091"
}


.af-icons-lg-fields:before {
  content: "\E092"
}


.af-icons-lg-judges:before {
  content: "\E093"
}


.af-icons-lg-categories:before {
  content: "\E094"
}


.af-icons-lg-rounds:before {
  content: "\E095"
}


.af-icons-lg-panels:before {
  content: "\E096"
}


.af-icons-lg-score-sets:before {
  content: "\E097"
}


.af-icons-lg-content:before {
  content: "\E098"
}


.af-icons-lg-chapters:before {
  content: "\E099"
}


.af-icons-lg-notifications:before {
  content: "\E09A"
}


.af-icons-lg-tabs:before {
  content: "\E09B"
}


.af-icons-lg-tick:before {
  content: "\E09C"
}


.af-icons-lg-checklist:before {
  content: "\E09D"
}


.af-icons-lg-payments:before {
  content: "\E09E"
}


.af-icons-lg-entry:before {
  content: "\E09F"
}


.af-icons-lg-cookie:before {
  content: "\E0A0"
}

