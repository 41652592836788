//
// Modified from http://flatlogic.github.io/awesome-bootstrap-checkbox/
// --------------------------------------------------

$font-family-icon: 'af-icons';
$check-icon: '\e038';

.checkbox label:after {
  padding-left: 4px;
  padding-top: 2px;
  font-size: 9px;
}

//
// Checkboxes
// --------------------------------------------------

.checkbox.styled{
  padding-left: 20px;

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    font-size: $font-size-base;

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0;
      margin-top: 1px;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 2px;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out, color 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      left: 1px;
      top: -2px;
      margin-top: 1px;
      margin-left: -20px;
      padding-left: 1px;
      padding-top: 0px;
      font-size: 14px;
      color: $input-color;
      line-height: 1.4;
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus + label::before{
      -webkit-box-shadow: 0 0 5px 1px #6f6f6f;
      -moz-box-shadow: 0 0 5px 1px #6f6f6f;
      box-shadow: 0 0 5px 1px #6f6f6f;
    }

    &:checked + label::after{
      font-family: $font-family-icon;
      content: $check-icon;
    }

    &:indeterminate + label::after{
      display: block;
      content: '';
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px;
    }

    &:disabled{
      cursor: not-allowed;
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        background-color: $input-bg-disabled;
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

  }

  &.checkbox-circle label::before{
    border-radius: 50%;
  }

  &.checkbox-inline{
    margin-top: 0;
  }
}

//
// Radios
// --------------------------------------------------

.radio.styled{
  padding-left: 20px;

  label{
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;

    &::before{
      content: '';
      display: inline-block;
      position: absolute;
      width: 18px;
      height: 18px;
      left: 0px;
      top: 2px;
      margin-left: -20px;
      border: 1px solid $input-border;
      border-radius: 50%;
      background-color: $input-bg;
      @include transition(border 0.15s ease-in-out);
    }

    &::after{
      display: inline-block;
      position: absolute;
      content: ' ';
      width: 10px;
      height: 10px;
      left: 4px;
      top: 6px;
      margin-left: -20px;
      border-radius: 50%;
      background-color: $colour-secondary;
      @include scale(0, 0);

      @include transition-transform(.1s cubic-bezier(.8,-0.33,.2,1.33));
      //curve - http://cubic-bezier.com/#.8,-0.33,.2,1.33
    }
  }

  input[type='radio']{
    opacity: 0;
    z-index: 1;
    cursor: pointer;

    &:focus + label::before{
      -webkit-box-shadow: 0 0 5px 1px #6f6f6f;
      -moz-box-shadow: 0 0 5px 1px #6f6f6f;
      box-shadow: 0 0 5px 1px #6f6f6f;
    }

    &:checked + label::after{
      @include scale(1, 1);
    }

    &:disabled{
      cursor: not-allowed;
    }

    &:disabled + label{
      opacity: 0.65;

      &::before{
        cursor: not-allowed;
      }

      &::after{
        cursor: not-allowed;
      }
    }

  }

  &.radio-inline{
    margin-top: 0;
  }
}

input[type='checkbox'],
input[type='radio'] {
  &.styled:checked + label:after {
    font-family: $font-family-icon;
    content: $check-icon;
  }

  .styled:checked + label {
    &::before {
      color: #fff;
    }

    &::after {
      color: #fff;
    }
  }
}
