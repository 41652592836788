/*
|--------------------------------------------------------------------------
| Base Elements
|--------------------------------------------------------------------------
|
| All the base elements should be placed here. Elements that are necessary
| for the app and that are used frequently throughout the app.
|
*/

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: white;
  color: $font-primary-colour;
  font: 400 14px/20px $font-primary;
}

// Message for unsupported browsers.
// Hidden on all normal browsers.
#unsupported-browser {
  background: $colour-error;
  border-radius: $default-radius;
  color: white;
  display: none;
  margin: 50px auto;
  padding: 30px;
  width: 600px;

  a {
    color: white;
    text-decoration: underline;
  }
}

#no-javascript {
  @extend #unsupported-browser;
  display: block;
}

small { font-size: 0.813em; }
