.accounts {
  .account {
    @include flexbox;
    @include flex-direction(column);
    max-width: 400px;
    border: 1px solid #000000;
    background-color: #ffffff;
    margin: 0 0 20px 0;
    border-radius: $border-radius-base;

    .screenshot {
      margin: -1px;

      img {
        @include semitransparent-outline();
      }
    }

    .details {
      padding: 10px 20px;

      h2, p {
        margin: 0 0 5px 0;
      }

      .af-icons-action-overflow {
        font-size: $af-icons-base-size;
        padding: 5px 15px;
        display: inline-block;
        margin-left: -24px;
      }
    }

    .feature-role {
      display: inline-block;
      margin-right: 10px;
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .accounts {
    .account {
      @include flex-direction(row);
      max-width: 100%;

      .screenshot img {
        width: 280px;
        max-width: 280px;
      }
    }
  }
}
