div[class^='alert-'] {
  border-radius: $default-radius;
  color: white;
  cursor: pointer;
  overflow: hidden;
  padding: 23px;
  display: table;
  width: 100%;

  // When alerts are followed by other alerts.
  & + & { margin-top: 10px; }

  &.sticky { cursor: default; }

  &.inline {
    margin: 10px 0;
    padding: 5px;
    background: transparent;
  }

  a {
    color: white;
    text-decoration: underline;
  }

  h1,
  h2,
  h3 {
    color: #fff;
  }

  .icon {
    display: table-cell;
    width: $af-icons-md-base-size;
    font-size: $af-icons-md-base-size;
  }

  .message {
    display: table-cell;
    padding-left: 20px;
    vertical-align: middle;

    p:first-child {
      margin-top: 0;
    }
  }

  p:last-child, ul:last-child {
    margin-bottom: .5em;
  }
}

// A list of array types followed by their colour so that we can loop through them and
// perform magic. Updating this list means you must update the sprite-alerts.png file as well.
$alert-types: info $colour-info,
  warning $colour-warning,
  success $colour-success,
  error $colour-error;

@for $i from 1 through length($alert-types) {
  $type-list: nth($alert-types, $i);
  $type: nth($type-list, 1);
  $colour: nth($type-list, 2);

  .alert-#{$type} {

    @include new-alert($colour, $i - 1);

    &.inline {
      color: $colour;
    }
  }
}

.alert {
  border: 0;
}

.alert-neutral {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 2px;
  color: $colour-form-box-text;
}
