// Cloak
[v-cloak] {
  display: none;
}

// Transitions
.fade-enter-active,
.fade-leave-active {
  @include transition(opacity .5s);
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// MultiselectList.vue
#wrapper-page {
  .multiselect-options {
    border: 1px solid $colour-input-border;
  }
}
