// ###############################################################
// Layout

.splash,
.app {
  @include flexbox;
  @include flex-direction(column);

  /**
   * Avoid the IE 10-11 `min-height` bug.
   *
   * https://css-tricks.com/couple-takes-sticky-footer
   * https://codepen.io/chriscoyier/pen/RRbKrL
   */
  height: 100vh;

  .wrapper {
    @include flex(1, 0, auto);
  }

  .footer {
    @include flex-shrink(0);
  }
}

.app {
  background-color: #fafafa;
}
