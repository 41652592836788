table {
  th, td {
    font: 400 14px/1.2em $font-primary;
    vertical-align: top;
  }

  &.middle,
  &.align-middle {
    th, td {
      vertical-align: middle;
    }
  }

  &.text-right {
    th, td {
      text-align: right;
    }
  }

  thead {
    $header-colour: lighten($font-primary-colour, 6);

    tr {
      color: $header-colour;
    }

    th {
      &.center {
        text-align: center;
      }
    }

    a {
      color: $header-colour;
      text-decoration: none;

      &:hover {
        color: #999;
      }

      // Sorting styles.
      &.sortable,
      &[class^='sort-'] {
        @include transition(all $transition-speed);
        background: url('../img/sort-icons.png') no-repeat;
        background-position: left -100px; // RTL converter support - it'll flip this but not background.
        padding-left: 17px;
      }

      &.sort-desc { background-position: left 4px; }

      &.sort-asc  { background-position: left -47px; }
    }
  }
}

.table {
  .af-icons-action-overflow {
    font-size: $af-icons-base-size;
    padding: 0 15px;
    display: inline-block;
    margin-left: -15px;
  }
}
