// Navbar =====================================================================

.navbar {
  border-width: 0;

  &-default {
    .badge {
      background-color: #fff;
      color: $navbar-default-bg;
    }
  }

  &-inverse {
    .badge {
      background-color: #fff;
      color: $navbar-inverse-bg;
    }
  }

  &-brand {
    line-height: 1;
  }

  .navbar-toggle {
    border: 0;

    .icon-bar {
      background: $navigation-text;
    }

    &:hover {
      .icon-bar {
        background: $navigation-text-hover;
      }
    }
  }
}

.navbar-collapse {
  @include box-shadow(none);
  border-top: 0;
}

.navbar-form {
  .form-control {
    border-width: 0;
  }
}

// Buttons ====================================================================

.btn {
  @include transition( background $transition-speed );
  display: inline-block;
  padding: 7px 20px;
  font-weight: 400;
  border: 0;
  display: inline-block;

  &.btn-xl {
    font-size: 1.8em;
  }
}

input.btn {
  width: auto;
}

.btn-lg {
  font-size: 1.285em;
  line-height: 1.2em;
}

.btn-md {
  font-size: 1.1em;
  line-height: 1.2em;
}

.btn-sm {
  font-size: 1em;
  line-height: 1.2em;
}

.btn-xs {
  font-size: .85em;
  line-height: 1.2em;
}

.btn-secondary {
  @include button-variant(#ffffff, $colour-secondary, $colour-secondary);
}

.btn-tertiary {
  @include button-variant(#ffffff, $colour-tertiary, $colour-tertiary);
}

.btn-transparent {
  @include button-variant(#ffffff, transparent, #ffffff);
  @include transition(border $transition-speed);
  border: 1px solid rgba(255, 255, 255, 1);

  &:hover {
    border: 1px solid rgba(255, 255, 255, .5);
  }
}

.btn:active {
  @include box-shadow(none);
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}

.btn-link {
  margin: 0;
  padding: 0;
}

// Labels =====================================================================

.label {
  padding: .1em .6em .15em;
  font-size: 90%;
  line-height: 1.8;
  font-weight: normal;
  border-radius: $border-radius-base;
}

// Pagination =================================================================

.pagination {
  margin: 0;

  li.disabled {
    opacity: 1;
  }
}

// Panels =====================================================================

.panel-body {
  h1:first-child,
  h2:first-child,
  h3:first-child {
    margin-top: 0;
  }
}

.panel-bordered {
  @include panel-variant($colour-input-border, $font-primary-colour, #fff, $colour-input-border);
}

// Typography =================================================================

p {
  margin: 1em 0px;
}

// Tables =====================================================================

.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        vertical-align: middle;
      }
    }
  }
}

// Forms ======================================================================

label {
  font-weight: normal;
  margin-bottom: 5px;
}

.form-control,
input {
  border-width: 1px;
  padding: 8px;
}

.input-sm {
  padding: 5px;
}

.has-warning {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-warning;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-warning;
  }

  .input-group-addon {
    border-color: $brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-danger;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-danger;
  }

  .input-group-addon {
    border-color: $brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-success;
  }

  .form-control,
  .form-control:focus {
    border: 1px solid $brand-success;
  }

  .input-group-addon {
    border-color: $brand-success;
  }
}

.input-group {
  margin-bottom: 5px;

  .input-group-addon {
    background: #efefef;
    color: $font-primary;
    font-size: 90%;
    min-width: 60px;
  }
}

.form-inline .form-group label {
  display: inline;
}

// Navs =======================================================================

// Indicators =================================================================

// Drop Downs =================================================================

.action-overflow .dropdown-toggle {
  display: inline-block;
  width: 16px;
  text-align: center;
}

.dropdown-menu {
  @include box-shadow(none);

  > li > a {
    padding: 6px 20px;
  }
}

// Popovers ===================================================================

.popover {
  @include box-shadow(none);

  padding: 0;
}

.popover-content {
  padding: 10px;
}

.popover.right {
  margin-left: 15px;
}

.popover.left {
  margin-left: -15px;
}

.popover.top {
  margin-top: -15px;
}

.popover.bottom {
  margin-top: 15px;
}

// Modals =====================================================================

.modal-dialog {
  margin-top: 60px;

  button.close {
    float: right;
    position: relative;
    top: -35px;
    right: -15px;
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 16px;
    text-shadow: none;
    opacity: 1;

    &:focus {
      outline: none;
    }
  }
}

.modal-content {
  @include box-shadow(none);
  border: none;
  border-radius: 0;
  background: #fafafa;
}

.modal-footer {
  padding: 15px;
}

// Containers =================================================================

.well {
  @include box-shadow(none);
}

.row-relative {
   position: relative;
   overflow: hidden;
}
