/*
|--------------------------------------------------------------------------
| Configuration
|--------------------------------------------------------------------------
| 
| Store all global variables and font declarations here.
| No styles should be defined here.
| 
*/

// Colours.
$colour-primary: #8b1360;
$colour-primary-hover: #6f0f4d;
$colour-secondary: #18aecf;
$colour-secondary-hover: #0f6475;
$colour-tertiary: #333;
$colour-tertiary-hover: #4e4e4e;
$colour-quaternary: #1d1d1d;

$colour-link: #106779;
$colour-link-hover: #1aafcd;
$colour-link-active: #137e94;

$colour-focus-box-text: $colour-link-hover;
$colour-focus-box-background: $colour-link;

$colour-form-box-text: #333;
$colour-form-box-background: #f4f4f4;

$colour-brand-header-background: #dedede;

$colour-header-link: $colour-link;
$colour-header-link-hover: $colour-link-hover;

$colour-breadcrumbs: #edeff2;

$colour-filters: #f9f9f9;
$colour-form: #f4f4f4;

$colour-warning: #c84503;
$colour-info: #106779;
$colour-success: #147524;
$colour-error: #e02365;
$colour-error-hover: lighten($colour-error, 20);
$colour-disabled: #767676;
$colour-disabled-input: #fafafa;
$colour-alert: #ff7500;

$colour-tab: #767676;
$colour-tab-text: #ffffff;
$colour-tab-hover: #8b1360;
$colour-tab-active: #1d1d1d;

$colour-border: #ddd;
$colour-input-border: #333;
$colour-input-border-focus: #333;
$colour-input-border-error: lighten($colour-error, 20%);
$colour-input-border-error-focus: darken($colour-input-border-error, 35%);

$colour-even-table-row: #f2f2f2;

$colour-approved: #009349;
$colour-rejected: #bb0000;

// Navigation
$navigation-background: $colour-quaternary;
$navigation-text: #8e96a2;
$navigation-text-hover: #fff;
$navigation-hover: #141414;

// Fonts.
@import url('//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

$font-primary: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-primary-colour: #333;
$font-secondary-colour: #989898;

// Directories and sprites.
$img: '../img/';
$sprite-main: $img + 'sprite.png';
$sprite-alerts: $img + 'sprite-alerts.png';
$sprite-file-icons: $img + 'sprite-file-icons.png';

// Misc settings.
$default-radius: 2px;

$transition-speed: 0.25s;

// Default width for modals.
$modal-width: 500px;

// Defaults for pill buttons.
$button-pill-height: 24px;
$button-pill-close: 20px;
$button-pill-close-spacing: 3px;

// Breakpoints.
$desktop:  new-breakpoint(min-width 1024px 12);
$tablet:   new-breakpoint(min-width 769px max-width 1023px 12);
$mobile:   new-breakpoint(max-width 768px 12);
$handheld: new-breakpoint(max-width 1023px 12);

// When viewed on mobile/tablet, this variable determines how much padding is
// added to the left and right of the element.
$handheld-device-padding: 20px;

// How far are the alerts from the edge of the screen?
$alerts-container-margin: 30px;
