/*
|--------------------------------------------------------------------------
| Main stylesheet file
|--------------------------------------------------------------------------
|
| This file will only load other libraries/files. There should be no
| styles defined here.
|
*/

// Config and normalization
@import 'config';
@import 'mixins';
@import 'normalize';

// Fonts generated by gulp
@import 'fonts/_config';
@import 'fonts/_af-icons';
@import 'fonts/_af-icons-md';
@import 'fonts/_af-icons-lg';
@import 'fonts/_glyphicons';

// Bootstrap.
@import 'bootstrap/__bootstrap';

// Vue.
@import 'vue';

// Shift's core styles.
@import 'shift/__shift';

// Styles that come from any plugin.
@import 'plugins/__plugins';

// Styles that come from components
@import 'components/__components';

// Page-specific styles.
@import 'pages/__pages';

// Miscellangeous styles. Can be used for page-specific styles.
@import 'misc';

// Shame.css - Anything you're not proud of, should go here.
@import 'shame';
