.field-pairing {
  position: relative;

  .subordinate {
    padding-left: 20px;
  }
}

.field-selector-control {
  width: 37px;
  height: 37px;
  background-color: #fff;
  border: 1px solid $colour-input-border;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: $border-radius-base;

  &:active {
    background-color: #fff;
  }
}

.field-selector-control-toggle {
  .af-icons-fill {
    padding: 10px;
  }
}

.field-selector-control-clear {
  .af-icons-cross {
    padding: 8px 12px;
  }
}

.field-selector {
  background-color: #fff;
  border: 1px solid $colour-input-border;
  width: 300px;
  position: absolute;
  top: 0px;
  right: -310px;
  border-radius: $border-radius-base;

  input[type='text'] {
    border: none;
    border-bottom: 1px solid $colour-input-border;
    width: 100%;
  }

  .list {
    max-height: 182px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        margin: 8px;
      }
    }
  }
}

.field-tag {
  background: $colour-link;
  color: #fff;
  font-size: 90%;
  padding: 2px 8px;
  border-radius: 10px;
}

a.field-tag {
  text-decoration: none;

  &:hover {
    background-color: $colour-link-hover;
    color: #fff;
  }
}
