.gravatar {
  border-radius: 100%;
}

.gravatar-default {
  @include inline-flex;
  @include align-items(center);
  @include justify-content(center);
  font-size: 275%;
  font-weight: 300;
  width: 80px;
  height: 80px;
  background: #000;
  color: #fff;
  vertical-align: middle;
}
