// Headings.
%headings-shared {
  color: $font-primary-colour;
	
  &.first { margin-top: 0; }

  &.collapse { margin: 0 }
}

h1, .h1 {
  @extend %headings-shared;
  font: 300 30px/40px $font-primary;
  letter-spacing: -1px;
}

h2, .h2 {
  @extend %headings-shared;
  font: 300 24px/34px $font-primary;
  letter-spacing: -1px;
}

h3, .h3 {
  @extend %headings-shared;
  font: 300 18px/26px $font-primary;
  letter-spacing: -1px;
}

h1, .h1,
h2, .h2,
h3, .h3 {
  a {
    color: $font-primary-colour;
  }

  a:hover,
  a:active,
  a:focus {
    color: $font-primary-colour;
    text-decoration: none;
  }
}

// Links.
a {
  color: $colour-link;
  cursor: pointer;
}

a,
.deleted a {
  &:hover {
    color: $colour-link-hover;
    text-decoration: underline;
  }

  &:active, &:focus {
    text-decoration: underline;
    background-color: transparent;
    outline: none !important; // disable dotted line on FF/IE after a link is clicked.
  }

  &:active {
    color: $colour-link-active;
  }
}

// Lists.
ul, ol {
  padding-left: 20px;
	
  // All nested unordered lists to have the same style.
  ul, ul ul, ul ul ul { list-style: disc; }
}

li {}

tr.warning,
td.warning,
span.warning {
  color: $colour-warning
}

.strong {
  font-weight: bold;
}

.inactive {
  color: #cc0000 !important;
}

.deleted,
.deleted a {
  color: $colour-error;
  text-decoration: line-through;
}

.timezone-output {
  color: $colour-disabled;
  font-size: 80%;
}
