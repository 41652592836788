@mixin lt-ie8 { .lt-ie8 & { @content; } }
@mixin lt-ie9 { .lt-ie9 & { @content; } }
@mixin lt-ie10 { .lt-ie10 & { @content; } }
@mixin lt-ie11 { .lt-ie11 & { @content; } }
@mixin ie { .ie & { @content; } }

// 
// Opacity mixin, adds support for older IE.
// 
@mixin opacity($opacity) {
  filter: alpha(opacity=#{$opacity * 100});
  opacity: $opacity;
}

// 
// SCSS Sprite helper based on a grid.
// 
@mixin sprite($x, $y, $img: $sprite-main, $grid-x: 50px, $grid-y: $grid-x) {
  background: url($img) no-repeat (-$x * $grid-x) (-$y * $grid-y);
}

// 
// SCSS Sprite position helper based on a grid.
// 
@mixin sprite-position($x, $y, $grid-x: 50px, $grid-y: $grid-x) {
  background-position: (-$x * $grid-x) (-$y * $grid-y);
}

// 
// Alert types helpers.
//
@mixin new-alert($colour, $grid-position) {
  $sprite-grid: 500px;
  $adjustment: 17px;
	
  background-color: $colour;
  background-position: 18px ($sprite-grid * -$grid-position + $adjustment);
	
  &:hover { background-color: darken($colour, 10); }

  &.sticky { background-color: $colour; }
}

//
// Verifies if a parameter is a number.
// https://css-tricks.com/snippets/sass/advanced-type-checking/
//
@function is-number($value) {
  @return type-of($value) == 'number';
}

//
// Verifies if a parameter is a percentage.
// https://css-tricks.com/snippets/sass/advanced-type-checking/
//
@function is-percentage($value) {
  @return is-number($value) and unit($value) == '%';
}

//
// Triangle mixin, adds a small triangle arrow on top of an element.
//
@mixin triangle($position: 'left', $background: #fff, $border: $colour-input-border) {
  &:after, &:before {
    bottom: 100%;
    @if is-percentage($position) {
      left: $position;
    } @else {
      @if $position == 'right' {
        left: 85%;
      } @else if $position == 'center' {
        left: 50%;
      } @else if $position == 'left' {
        left: 15%;
      }
    }
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba($background, 0);
    border-bottom-color: $background;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba($border, 0);
    border-bottom-color: $border;
    border-width: 11px;
    margin-left: -11px;
  }
}

//
// Semitransparent outline.
//
@mixin semitransparent-outline($transparency: 0.125, $offset: -1px) {
  outline: 1px solid rgba(0, 0, 0, $transparency);
  outline-offset: $offset;
}

//
// Transform.
//
@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

//
// Position.
// http://bourbon.io/docs/#position
//
@mixin position($position: relative, $coordinates: 0 0 0 0) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }

  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  position: $position;

  @if $top == auto {
    top: $top;
  }
  @else if not(unitless($top)) {
    top: $top;
  }

  @if $right == auto {
    right: $right;
  }
  @else if not(unitless($right)) {
    right: $right;
  }

  @if $bottom == auto {
    bottom: $bottom;
  }
  @else if not(unitless($bottom)) {
    bottom: $bottom;
  }

  @if $left == auto {
    left: $left;
  }
  @else if not(unitless($left)) {
    left: $left;
  }
}

//
// Size.
// http://bourbon.io/docs/#size
//
@mixin size($size) {
  @if length($size) == 1 {
    @if $size == auto {
      width:  $size;
      height: $size;
    }

    @else if unitless($size) {
      width:  $size + px;
      height: $size + px;
    }

    @else if not(unitless($size)) {
      width:  $size;
      height: $size;
    }
  }

  // Width x Height
  @if length($size) == 2 {
    $width:  nth($size, 1);
    $height: nth($size, 2);

    @if $width == auto {
      width: $width;
    }
    @else if not(unitless($width)) {
      width: $width;
    }
    @else if unitless($width) {
      width: $width + px;
    }

    @if $height == auto {
      height: $height;
    }
    @else if not(unitless($height)) {
      height: $height;
    }
    @else if unitless($height) {
      height: $height + px;
    }
  }
}

//
// Flexbox containers.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flexbox {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

@mixin inline-flex {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

//
// Flexbox direction.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex-direction($value: row) {
  @if $value == row-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: horizontal;
    -moz-box-direction: reverse;
    -moz-box-orient: horizontal;
  } @else if $value == column {
    -webkit-box-direction: normal;
    -webkit-box-orient: vertical;
    -moz-box-direction: normal;
    -moz-box-orient: vertical;
  } @else if $value == column-reverse {
    -webkit-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-direction: reverse;
    -moz-box-orient: vertical;
  } @else {
    -webkit-box-direction: normal;
    -webkit-box-orient: horizontal;
    -moz-box-direction: normal;
    -moz-box-orient: horizontal;
  }
  -webkit-flex-direction: $value;
  -ms-flex-direction: $value;
  flex-direction: $value;
}

//
// Flexbox wrap.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex-wrap($value: nowrap) {
  // No Webkit/FF Box fallback.
  -webkit-flex-wrap: $value;
  @if $value == nowrap {
    -ms-flex-wrap: none;
  } @else {
    -ms-flex-wrap: $value;
  }
  flex-wrap: $value;
}

//
// Flexbox flow (shorthand).
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex-flow($values: (row nowrap)) {
  // No Webkit/FF Box fallback.
  -webkit-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

//
// Flexbox order.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin order($int: 0) {
  -webkit-box-ordinal-group: $int + 1;
  -moz-box-ordinal-group: $int + 1;
  -webkit-order: $int;
  -ms-flex-order: $int;
  order: $int;
}

//
// Flexbox grow.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex-grow($int: 1) {
  -webkit-box-flex: $int;
  -moz-box-flex: $int;
  -webkit-flex-grow: $int;
  -ms-flex: $int;
  flex-grow: $int;
}

//
// Flexbox shrink.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex-shrink($int: 0) {
  -webkit-flex-shrink: $int;
  -moz-flex-shrink: $int;
  -ms-flex: $int;
  flex-shrink: $int;
}

//
// Flexbox basis.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex-basis($value: auto) {
  -webkit-flex-basis: $value;
  flex-basis: $value;
}

//
// Flexbox "flex" (shorthand).
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin flex($fg: 1, $fs: 0, $fb: auto) {

  // Set a variable to be used by box-flex properties
  $fg-boxflex: $fg;

  // Box-Flex only supports a flex-grow value so lets grab the
  // first item in the list and just return that.
  @if type-of($fg) == 'list' {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box: $fg-boxflex;
  -moz-box: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -ms-flexbox: $fg $fs $fb;
  flex: $fg $fs $fb;
}

//
// Flexbox justify content.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -moz-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -moz-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -moz-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  justify-content: $value;
}

//
// Flexbox align items.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -moz-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  align-items: $value;
}

//
// Flexbox align self.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

//
// Flexbox align content.
// https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mixins
//
@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}
