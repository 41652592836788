.footer {
  padding: 20px 0;

  .copyright {
    font-size: .8em;

    a {
      color: $font-primary-colour;
      text-decoration: underline;

      &:hover {
        color: $font-primary-colour;
        text-decoration: none;
      }
    }
  }

  .language {
    > a {
      .name,
      .af-icons {
        vertical-align: middle;
      }

      .af-icons {
        font-size: 120%;
      }

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
