.user-menu {
  display: inline-block;

  > .dropdown > a {
    text-decoration: none;
  }

  ul {
    margin: 0;
    @include triangle(25%);
  }
}
