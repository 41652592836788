/*
|--------------------------------------------------------------------------
| Miscellaneous styles
|--------------------------------------------------------------------------
|
| Small styles that are related to any page, or misc styles that don't
| properly fit in another file/category.
|
*/

// Drop-down menu items with icons
.dropdown-menu > li > a {
  .af-icons {
    line-height: $line-height-base;
  }
}

// Customisations to Datepicker
.app {
  .datepicker {
    .day.selected {
      background-color: $colour-primary;

      &:hover {
        background-color: $colour-primary;
      }
    }
  }
}

// Labels
.labels {
  margin-top: 5px;
  max-width: 500px;

  .label-tag {
    background-color: $font-primary-colour;
  }
}

// Scroll overflowing content vertically
.scroll-vertically {
  overflow-y: auto;

  // Pure CSS scrolling shadow effect inspired by:
  // http://lea.verou.me/2012/04/background-attachment-local/
  &.shadows {
    background-image:
      linear-gradient(to bottom, white, white),
      linear-gradient(to bottom, white, white),
      linear-gradient(to bottom, rgba(0,0,0,.1), rgba(255,255,255,0)),
      linear-gradient(to top, rgba(0,0,0,.1), rgba(255,255,255,0));

    background-position: top center, bottom center, top center, bottom center;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 10px, 100% 10px, 100% 5px, 100% 5px;

    background-attachment: local, local, scroll, scroll;
  }
}

// Icon customisations
.breadcrumb-icon {
  font-size: 0.8em;
}
