div.pagination-nav {
  overflow: hidden;
  margin: 20px 0;
  width: 100%;

  ul {
    float: right;
    position: relative;
  }
}

.per-page {
  width: auto;
  float: right;
  margin-left: 70px;
}
