@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.auth {
  margin-top: 60px;

  div[class^='alert-'] {
    margin-bottom: 60px;

    &.inline {
      color: #ffffff;
      margin-bottom: 0;
    }
  }

  .form-control {
    border-width: 0;
  }

  .form-group:last-child {
    margin-bottom: 0;
  }

  .box {
    @include flexbox;
    background: $colour-primary;
    padding: 20px;
    color: white;
    position: relative;
    border-radius: $border-radius-base;

    // Slowly transition between background colours
    @include transition(background-color 3s);

    // Make the login box square (360 x 360 px) on 'lg' screen size
    min-height: 360px;

    .form-container {
      @include flex(1, 1, auto);

      // Attach the form to the bottom of the flex container
      @include align-self(flex-end);
    }

    a,
    .af-icons {
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }
    }

    .logo-af {
      width: 80px;
      height: 80px;
      margin: auto;
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -40px;
    }

    .logo-gg {
      width: 80px;
      height: 100px;
      margin: auto;
      position: absolute;
      top: -40px;
      left: 50%;
      margin-left: -40px;
    }

    .btn {
      width: 100%;
    }
  }

  .social-auth {
    .providers {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .btn {
        margin-bottom: 8px;
      }
    }

    .btn {
      margin: 0 5px;
    }

    .btn-google {
      @include button-variant(#ffffff, #4285F4, #e5e5e5);
      padding: 0;
      overflow: hidden;
      font-family: 'Roboto', sans-serif;

      .btn-google-container {
        display: flex;
        align-items: center;

        .google-logo {
          height: 36px;
          padding: 2px;
        }

        .google-text {
          margin-left: 15px;
          padding-right: 15px;
        }
      }

      &:hover {
        -webkit-box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
        box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
      }
    }
    
    .btn-facebook {
      @include button-variant(#ffffff, #4c66a4, #4c66a4);
      min-height: 36px;
      padding: 8px 15px;
    }

    .btn-twitter {
      @include button-variant(#ffffff, #55acee, #55acee);
      min-height: 36px;
      padding: 8px 15px;
    }
    
    .btn-linkedin {
      @include button-variant(#ffffff, #0E76A8, #0E76A8);
      min-height: 36px;
      padding: 8px 15px;
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .auth {
    margin-top: 120px;
  }
}
